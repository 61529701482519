import { Button, Divider, Heading } from '~elements'
import Image from 'next/image'
import shareicon from '../../../public/assets/share.svg'
import Link from 'next/link'
import { SlidingPanelProps } from 'types/calendar'
import styles from './SlidingPanel.module.scss'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { useRouter } from 'next/router'
import { isProd, PST_TZ } from '~constants'
import { constructURL } from 'utils'
import { ButtonProps } from '~types'

const SlidingPanel = (props: SlidingPanelProps) => {
  const [disableBuyTicketsButton, setDisableBuyTicketsButton] = useState(true)
  const [activeButton, setActiveButton] = useState(null)
  const { query } = useRouter()
  const router = useRouter()

  const { date, time, id } = query

  const { artistDetail, disclaimer, eventInfo, businessUnit } = props ?? {}

  const artistDetails = artistDetail.reduce((acc, field) => {
    acc[field?.artistId] = {
      ...field
    }
    return acc
  }, {})
  const artistId = eventInfo && eventInfo?.[0]?.['artists']?.[0]?.id
  const selectedArtist = artistDetails[artistId]
  const DATE_FORMATTER = {
    FULL_DATE: 'MMMM D, YYYY | ddd',
    DATE: 'MM-DD-YYYY'
  }

  const eventTimestamp = eventInfo && moment.tz(eventInfo[0]['date_time'], PST_TZ)
  const formattedDate = eventTimestamp?.format(DATE_FORMATTER.FULL_DATE)
  const [name, setName] = useState('')
  const [hostUrl, setHostUrl] = useState('')
  const firstEventInfoValue = eventInfo && eventInfo[0]

  useEffect(() => {
    if (eventInfo && !id && props.showSlidingWindow) {
      handleTimeButtonClick(firstEventInfoValue?.id, firstEventInfoValue?.name, firstEventInfoValue?.host_url)
    }
  }, [props.showSlidingWindow])

  useEffect(() => {
    if (router.isReady) {
      const { id } = router.query
      if (id) {
        setActiveButton(id)
      }
    }
  }, [router.isReady, router.query.id])

  useEffect(() => {
    if (props) {
      setName('')
      // setHostUrl('')
    }
  }, [props])
  const handleShowSlidingWindow = () => {
    props.handleShowSlidingWindow(false)
    setDisableBuyTicketsButton(true)
    setActiveButton(null)

    document.getElementsByTagName('html')[0].style.overflowY = 'scroll'
  }
  useEffect(() => {
    if (!props.showSlidingWindow) {
      setDisableBuyTicketsButton(true)
      setActiveButton(null)
      if (id) {
        router.push(
          {
            pathname: '/shows',
            query: ''
          },
          undefined,
          { shallow: true }
        )
      }
    } else {
      if (id && eventInfo) {
        const selectedId = eventInfo.filter(item => item.id === id)
        handleTimeButtonClick(selectedId[0]?.id, selectedId[0]?.name, selectedId[0]?.host_url)
      }
    }
  }, [props.showSlidingWindow])

  const handleEscapeKey = event => {
    if (event.key === 'Escape') {
      handleShowSlidingWindow()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey)
    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [])

  const handleTimeButtonClick = (id, name, hostUrl) => {
    if (eventInfo) {
      const [showInfo] = eventInfo
      const { date_time: dateTime, host_url: defaultHostUrl } = showInfo
      const [filteredEventData] = eventInfo?.filter(show => show.id === id)
      const { host_url: tmUrl } = filteredEventData ?? {}
      const eventTimestamp = dateTime && moment.tz(dateTime, PST_TZ)
      const formattedDate = eventTimestamp?.format(DATE_FORMATTER.DATE)
      const updateHostUrl = hostUrl ? hostUrl : tmUrl ? tmUrl : defaultHostUrl
      const queryParams = {
        startdate: formattedDate,
        id: id
      }
      router.push(
        {
          pathname: '/shows',
          query: queryParams
        },
        undefined,
        { shallow: true }
      )
      setActiveButton(id)
      setName(name)
      setHostUrl(updateHostUrl)
      setDisableBuyTicketsButton(false)
    }
  }
  const [source] = query.slug as string[]

  const getCtaUrl = (buttonProps: ButtonProps) => {
    if (buttonProps.title.toLowerCase() === 'buy tickets' && hostUrl) {
      const url = new URL(hostUrl)

      if (buttonProps.wantUtm) {
        url.searchParams.append('utm_source', 'sphere')
        url.searchParams.append('utm_medium', 'web')
      }

      if (buttonProps.wantCfc) {
        url.searchParams.append('camefrom', 'CFC_SPHERE')
      }

      return url.toString()
    }

    return buttonProps?.url
  }
  const handleBuyTicketButtonOnClick = (buttonProps: any) => {
    const currentEvent = eventInfo.find((x: any) => x.id === activeButton)

    const snakeCaseHeadline = selectedArtist.artistName
      ? selectedArtist.artistName
          .split(' ')
          .map(x => x.toLocaleLowerCase())
          .join('_')
      : ''

    if (buttonProps.title.toLowerCase() === 'buy tickets') {
      gaBuyTicketsClick({
        businessUnit,
        buyType: buttonProps.buyType,
        clickSource: source ?? '',
        clickTracking: `${window.location.href} ${snakeCaseHeadline}:buy_tickets`,
        eventDate: moment(currentEvent.date_time).format('MM/DD/YYYY'),
        eventVenueName: 'sphere_vegas',
        eventCategory: currentEvent.category.length > 0 ? currentEvent.category[0].value : '',
        eventType: currentEvent.category.length > 0 ? currentEvent.category[0].value : '',
        eventDataStatus: currentEvent.sales_status,
        linkLocation: SlidingPanel.displayName,
        linkPosition: '',
        ticketmasterEventId: currentEvent.id,
        cmsIdentifier: buttonProps.cmsIdentifier,
        buttonPlacementDescription: buttonProps.buttonPlacementDescription
      })
    }
  }

  return (
    <>
      <div className={styles['slide-module']} style={props.showSlidingWindow ? { right: 0 } : { right: '-512px' }}>
        <div
          className={styles['module-content-image']}
          style={{
            backgroundImage: `url("${selectedArtist?.artistImageURLS3}")`
          }}
        >
          <FontAwesomeIcon icon={faXmark} style={{ fontSize: '10px' }} onClick={handleShowSlidingWindow} />
          {selectedArtist && (
            <Link href={selectedArtist?.edpUrl} target="_blank">
              <div className={styles['module-content-image-content']}>
                <div className={styles['share-icon']}>
                  <Image src={shareicon} alt="share" />
                </div>
                {selectedArtist?.artistName && <Heading level={4}>{selectedArtist?.artistName}</Heading>}
                {selectedArtist?.description.html && (
                  <p>
                    {parse(
                      selectedArtist?.description.html.length > 115
                        ? selectedArtist?.description.html.substring(0, 115) + '...'
                        : selectedArtist?.description.html
                    )}{' '}
                  </p>
                )}
              </div>
            </Link>
          )}
        </div>
        <div className={styles['bottom-module']}>
          <div className={styles['module-content-calender']}>
            <div className={styles['list-item-date-holder']}>
              <div className={styles['list-item__date']}>
                <span className={styles['list-item__formatted-date']}>{formattedDate}</span>
              </div>
            </div>
            <div className={styles['list-item-ctas']}>
              {eventInfo?.map(data => {
                return data ? (
                  <div key={data.id}>
                    <Button
                      color="light"
                      disabled={false}
                      fill={activeButton === data.id ? 'solid' : 'hollow'}
                      size="sm"
                      title={data.formatted_start_time}
                      onClick={() => handleTimeButtonClick(data?.id, data?.name, data?.host_url)}
                    />
                  </div>
                ) : null
              })}
            </div>
          </div>
          <Divider />
          <div className={styles['module-content-footer']}>
            {selectedArtist?.cta && selectedArtist?.cta.length > 0 && (
              <div className={styles['module-content-footer-buttons']}>
                {selectedArtist?.cta.map((buttonProps, index) => (
                  <Button
                    asLink
                    id={isProd ? 'buy-tickets-btn-calendar-ga' : ''}
                    key={`${buttonProps.title}_${index}`}
                    {...buttonProps}
                    displayLinkInline
                    isTargetBlank={buttonProps.title.toLowerCase() === 'buy tickets' ? true : false}
                    onClick={() => handleBuyTicketButtonOnClick(buttonProps)}
                    disabled={disableBuyTicketsButton && buttonProps.title.toLowerCase() === 'buy tickets' ? true : false}
                    url={getCtaUrl(buttonProps)}
                  />
                ))}
              </div>
            )}
            <div className={styles['module-content-footer-text']}>{props.description?.html && parse(props.description?.html)}</div>
            <div className={`${styles['module-content-footer-disclaimer']} `}>{parse(disclaimer?.html)}</div>
          </div>
        </div>
      </div>
      {props.showSlidingWindow ? <div className={styles['overlay']} onClick={handleShowSlidingWindow}></div> : ''}
    </>
  )
}
SlidingPanel.displayName = 'sliding_panel'

export default SlidingPanel
