import { useEffect, useRef, useState } from 'react'
import styles from './InteractiveOverlayCard.module.scss'
import { Button, Heading } from '~elements'
import { Col } from 'react-grid-system'
import { ButtonProps } from '~types'
import Image from 'next/image'

import { useWindowSize } from 'utils/hooks'
import { getCustomBreakpoint } from 'utils/hooks'
import { useIsIntersecting } from 'utils/hooks/useIsIntersecting'
import { useRouter } from 'next/router'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { constructS3Url } from 'utils'

interface SliderInterface {
  slickNext: () => void
  slickPrev: () => void
}

interface InteractiveOverlayCardProps {
  headline: string
  highlightedWords?: string
  subheadline: string
  eyebrow: string
  cta: ButtonProps
  listItems: string[]
  onListItemClick?: (index: number) => void
  backgroundImages?: {
    url: string
  }[]
  mobileBackgroundImages?: {
    url: string
  }[]
  handleInteractiveOverlayCardLoad: (isLoaded: boolean) => void
  businessUnit?: string
  mobileBackgroundImageUrLsS3: Array<string>
  backgroundImageUrLsS3: Array<string>
}

const InteractiveOverlayCard = ({
  headline,
  highlightedWords,
  eyebrow,
  subheadline,
  image,
  listItems,
  onListItemClick,
  backgroundImages,
  mobileBackgroundImages,
  cta,
  handleInteractiveOverlayCardLoad,
  businessUnit,
  mobileBackgroundImageUrLsS3,
  backgroundImageUrLsS3
}: InteractiveOverlayCardProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { isBreakpoint: isSmartphoneScreen } = getCustomBreakpoint(431, '<')
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
  const [activeBackgroundImage, setActiveBackgroundImage] = useState<string>('')
  const sliderRef = useRef<SliderInterface | null>(null)
  const timerRef = useRef<number | null>(null)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [touchX, setTouchX] = useState<number | null>(null)
  const [swipeDirection, setSwipeDirection] = useState<'left' | 'right' | null>(null)
  const [preloadedImages, setPreloadedImages] = useState<string[]>([])
  const { query } = useRouter()
  const [source] = query.slug as string[]

  const handleButtonClick = ({ buyType, cmsIdentifier, buttonPlacementDescription, displayFevoWidget }: ButtonProps) => {
    if (!displayFevoWidget) {
      gaBuyTicketsClick({
        businessUnit,
        buyType: buyType,
        clickSource: source ?? '',
        clickTracking: `${window.location.href} edp_header:buy_tickets`,
        eventDate: '',
        eventVenueName: 'sphere_vegas',
        eventCategory: 'other',
        eventType: 'other events',
        eventDataStatus: '',
        linkLocation: InteractiveOverlayCard.displayName,
        linkPosition: '',
        ticketmasterEventId: '',
        cmsIdentifier: cmsIdentifier,
        buttonPlacementDescription: buttonPlacementDescription
      })
    }
  }

  useEffect(() => {
    const preloadImages = async (imagesArray: string[] | undefined) => {
      if (!imagesArray) {
        return []
      }
      const imagesToPreload = imagesArray?.map(bgImage => bgImage) || []
      const promises = imagesToPreload.map(url => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = url
          img.onload = resolve
          img.onerror = reject
        })
      })

      try {
        await Promise.all(promises)
        return imagesToPreload
      } catch (error) {
        console.error('Image preload failed:', error)
        return []
      }
    }

    const preloadAllImages = async () => {
      const desktopImages = await preloadImages(backgroundImageUrLsS3)
      const mobileImages = await preloadImages(mobileBackgroundImageUrLsS3)

      setPreloadedImages([...desktopImages, ...mobileImages])
    }

    preloadAllImages()
  }, [backgroundImageUrLsS3, mobileBackgroundImageUrLsS3])

  useEffect(() => {
    const imagesArray = isSmartphoneScreen ? mobileBackgroundImageUrLsS3 : backgroundImageUrLsS3
    if (preloadedImages.length > 0 && imagesArray) {
      setActiveBackgroundImage(imagesArray[activeTabIndex] || '')
    }
  }, [activeTabIndex, preloadedImages, isSmartphoneScreen, backgroundImageUrLsS3, mobileBackgroundImageUrLsS3])

  const swipeDistanceLimit = 50

  const isElementIntersecting = useIsIntersecting({
    ref: containerRef
  })

  useEffect(() => {
    if (isSmartphoneScreen) {
      if (mobileBackgroundImageUrLsS3 && mobileBackgroundImageUrLsS3.length > 0) {
        setActiveBackgroundImage(mobileBackgroundImageUrLsS3[activeTabIndex] || '')
      }
    } else {
      if (backgroundImageUrLsS3 && backgroundImageUrLsS3.length > 0) {
        setActiveBackgroundImage(backgroundImageUrLsS3[activeTabIndex] || '')
      }
    }
  }, [activeTabIndex, backgroundImageUrLsS3, mobileBackgroundImageUrLsS3, isSmartphoneScreen])

  useEffect(() => {
    if (containerRef && containerRef.current) {
      if (isElementIntersecting) {
        handleInteractiveOverlayCardLoad(true)
      } else {
        handleInteractiveOverlayCardLoad(false)
      }
    }
  }, [isElementIntersecting, containerRef])

  const prevSlideHandler = () => {
    const newIndex = activeTabIndex === 0 ? listItems.length - 1 : activeTabIndex - 1
    setActiveTabIndex(newIndex)
    sliderRef?.current?.slickPrev()
  }

  const nextSlideHandler = () => {
    const newIndex = activeTabIndex === listItems.length - 1 ? 0 : activeTabIndex + 1
    setActiveTabIndex(newIndex)
    sliderRef?.current?.slickNext()
  }

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const isIntersecting = entries[0].isIntersecting
      if (isIntersecting && !isMouseOver) {
        timerRef.current = setInterval(() => {
          setActiveTabIndex(prevIndex => (prevIndex + 1) % listItems.length)
        }, 3000)
      } else {
        clearInterval(timerRef.current)
      }
    }

    const intersectionObserver = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    })

    if (containerRef.current) {
      intersectionObserver.observe(containerRef.current)
    }

    return () => {
      clearInterval(timerRef.current)
      intersectionObserver.disconnect()
    }
  }, [activeTabIndex, listItems.length, isMouseOver])

  const handleListItemClick = (index: number) => {
    setActiveTabIndex(index)
    clearInterval(timerRef.current)
    onListItemClick && onListItemClick(index)
  }

  const handleBulletPointHover = () => {
    setIsMouseOver(true)
    clearInterval(timerRef.current)
  }

  const handleBulletPointLeave = () => {
    setIsMouseOver(false)
  }

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchX(e.touches[0].clientX)
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (touchX && listItems.length > 1) {
      const xPosition = e.touches[0].clientX
      const distance = Math.abs(touchX - xPosition)
      if (distance > swipeDistanceLimit) {
        if (touchX > xPosition) {
          setSwipeDirection('left')
        } else {
          setSwipeDirection('right')
        }
      }
    }
  }

  const handleTouchEnd = () => {
    if (touchX && listItems.length > 1) {
      if (swipeDirection === 'right') {
        prevSlideHandler()
      } else if (swipeDirection === 'left') {
        nextSlideHandler()
      }
    }
    setTouchX(null)
    setSwipeDirection(null)
  }

  return (
    <Col>
      <div
        className={styles['interactive-overlay-card-external']}
        style={{
          backgroundImage: `url(${constructS3Url(activeBackgroundImage)})`
        }}
        ref={containerRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        {isBelowBreakpointMd && (
          <div className={styles['indicator-position']}>
            {listItems.map((listItem, index) => (
              <span
                key={`indicator-position-${index}`}
                className={`${styles['indicator']} ${index === activeTabIndex ? styles['active-indicator'] : ''}`}
              ></span>
            ))}
          </div>
        )}
        <div className={styles['overlay-card-container']}>
          <div className={styles['title-text']}>
            {headline && (
              <Heading level={2}>
                {headline}
                {highlightedWords && <span className={styles['highlight-word']}>{' ' + highlightedWords}</span>}
              </Heading>
            )}
            {eyebrow && (
              <Heading level={3} levelDisplay="eyebrow" color="light">
                {eyebrow}
              </Heading>
            )}
            {!isBelowBreakpointMd && (
              <div className={styles['standalone-btn']}>
                <Button {...cta} onClick={() => handleButtonClick(cta)} />
              </div>
            )}
          </div>
          <div className={styles['offerings']}>
            {subheadline && (
              <Heading level={3} color="light">
                {subheadline}
              </Heading>
            )}
            {listItems && (
              <div className={styles['list-items']}>
                <ul>
                  {listItems.map((listItem, index) => (
                    <li
                      className={
                        isSmartphoneScreen
                          ? activeTabIndex === index
                            ? styles['mobile-list-item']
                            : styles['backgroundTransparent']
                          : activeTabIndex === index
                          ? styles['backgroundBlack']
                          : styles['backgroundTransparent']
                      }
                      key={index}
                      onClick={() => handleListItemClick(index)}
                      onMouseEnter={handleBulletPointHover}
                      onMouseLeave={handleBulletPointLeave}
                    >
                      {listItem}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </Col>
  )
}

InteractiveOverlayCard.displayName = 'interactive_overlay_card'

export default InteractiveOverlayCard
