import React, { Suspense, lazy } from 'react'
import cx from 'classnames'
import parse from 'html-react-parser'
import { Button, Classification, Heading, Paragraph, Quote } from '~elements'
import styles from './EDPFullScreenVideo.module.scss'
import { ButtonProps, ImageProps, VideoProps } from '~types'
import { getCustomBreakpoint } from 'utils/hooks'
import { gaBtnClick, gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT, HOME_PAGE_VIDEO } from '~analyticsConstants'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { constructS3Url } from 'utils'

const EDPVideo = lazy(() => import('components/elements/Video/Video'))

interface ClassificationProps {
  quoteTitle: string
  quoteDescription: {
    html: string
  }
  quoteSubHeadline: string
  quoteCopy: {
    html: string
  }
  classficiationTitle: string
  classficiationDescription: string
  classficiationRating: number
  classficiationRatingIcon: ImageProps
  starImageUrlS3: string
}

interface EDPFullScreenVideoProps {
  backgroundVideo: {
    backgroundVideoXl: VideoProps
    backgroundVideoMd: VideoProps
    backgroundVideoSm: VideoProps
    posterImageXl: ImageProps
    posterImageMd: ImageProps
    posterImageSm: ImageProps
    videoLoop: boolean
    posterImageXlUrlS3: string
    posterImageMdUrlS3: string
    posterImageSmUrlS3: string
  }
  ctaPrimary: ButtonProps
  quote: Array<ClassificationProps>
  businessUnit?: string
}

const EDPFullScreenVideo = ({ backgroundVideo, ctaPrimary, quote, businessUnit }: EDPFullScreenVideoProps) => {
  const {
    backgroundVideoXl,
    backgroundVideoMd,
    backgroundVideoSm,
    posterImageXl,
    posterImageMd,
    posterImageSm,
    videoLoop,
    posterImageXlUrlS3,
    posterImageMdUrlS3,
    posterImageSmUrlS3
  } = backgroundVideo
  const { isBreakpoint: isAboveBreakpointMd } = getCustomBreakpoint(768, '>')
  const { isBreakpoint: isAboveBreakpointXL } = getCustomBreakpoint(1439, '>')

  const edpVideo = isAboveBreakpointXL ? backgroundVideoXl : isAboveBreakpointMd ? backgroundVideoMd : backgroundVideoSm
  const posterImage = isAboveBreakpointXL ? posterImageXlUrlS3 : isAboveBreakpointMd ? posterImageMdUrlS3 : posterImageSmUrlS3

  const { query } = useRouter()
  const [source] = [...(query.slug as string[])].reverse()

  const gaBtnClick = (buttonProps: ButtonProps) => {
    gaBuyTicketsClick({
      businessUnit,
      buyType: buttonProps.buyType,
      clickSource: source ?? '',
      clickTracking: `${window.location.href} edp_full_screen_video:buy_tickets`,
      eventDate: '',
      eventVenueName: 'sphere_vegas',
      eventCategory: 'other',
      eventType: 'other events',
      eventDataStatus: '',
      linkLocation: EDPFullScreenVideo.displayName,
      linkPosition: '',
      ticketmasterEventId: '',
      cmsIdentifier: buttonProps.cmsIdentifier,
      buttonPlacementDescription: buttonProps.buttonPlacementDescription
    })
  }

  const [classification] = quote
  return (
    <>
      <div className={styles['edp-full-screen-video']}>
        {ctaPrimary && isAboveBreakpointMd && (
          <div className={cx(styles['cta-container'], styles['is-visible'])}>
            <Button dataID="edp-buy-btn" onClick={() => gaBtnClick(ctaPrimary)} {...ctaPrimary} size={'lg'} />
          </div>
        )}
        {classification && classification.quoteTitle && <Quote classification={classification} />}
        {classification && classification.classficiationTitle && <Classification classification={classification} />}
        <Suspense fallback={<>Loading...</>}>
          <EDPVideo videoLoop={videoLoop} posterImage={{ url: constructS3Url(posterImage) }} backgroundVideo={edpVideo} />
        </Suspense>
      </div>
    </>
  )
}

EDPFullScreenVideo.displayName = 'edp_full_screen_video'

export default EDPFullScreenVideo
