import React from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import styles from './Location.module.scss'
import { Col } from 'react-grid-system'
import { Heading } from '~elements'
import { LocationProps } from '~types'
import { fireAnalytics, formatAnalyticsText, getPageType } from '~analyticsUtils'
import { ANALYTICS_EVENT_BUTTON_CLICK, ANALYTICS_LINK_MODULE_GLOBAL_NAV, ANALYTICS_LINK_LOCATION_BODY } from '~analyticsConstants'

const Location = ({ ctaText, isLastChild, title, url }: LocationProps) => {
  const { asPath, query } = useRouter()

  const requestCameFromUrl = url
  const analyticsLinkData = (title: string) => ({
    linkId: formatAnalyticsText(title),
    linkModule: ANALYTICS_LINK_MODULE_GLOBAL_NAV,
    linkLocation: ANALYTICS_LINK_LOCATION_BODY
  })

  const handleClick = (title: string, url: string) => {
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), analyticsLinkData(title))
    window.open(requestCameFromUrl)
  }

  return (
    <Col lg={4} className={cx([styles['location-container'], styles[isLastChild ? 'is-last-child' : '']])}>
      <div className={styles['location']} onClick={() => handleClick(title, url)}>
        <div className={styles['location-content']}>
          <div className={styles['location-content-inner']}>
            <Heading level={3}>{title}</Heading>
            <strong>{ctaText}</strong>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default Location
