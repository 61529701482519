// Breakpoints
export const BREAKPOINT_SM_MAX = 767

// Navigation Header sublinks
export const MAX_LINKS_PER_SECTION = 5

//Vimeo autoplay url
export const AUTO_PLAY_URL = '?autoplay=true'

// Slugs
export const SLUG_HOMEPAGE = '/home'
export const HOME_URL = '/'
export const SLUG_XO_GALLERY = '/xo-student-design-challenge/elementary'

// Fonts
export const TYPEKIT_CSS_URL = 'https://use.typekit.net/bbg3yei.css'

export const SCROLL_ANIMATING = {
  START: 'start',
  STOP: 'stop'
}

// Formstack
export const FORMSTACK_URL = 'https://www.msg.com/formstack'

//Environments

export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || 'development'
export const isProd = APP_ENV === 'prod3'

// Revalidation Interval (in seconds) at (60 * n)
export const REVALIDATION_INTERVAL = 120 * 10

//24 hours in milliseconds
export const TWENTY_FOUR_HOURS_IN_MS = 24 * 60 * 60 * 1000

export const READ_MORE_WORD_COUNT_MAX = 40

export const ONE_TRUST_TITLE = 'OneTrustLink'

export const VIDEO_RATIO = 0.5625

export const DAYS_OF_THE_WEEK = [0, 1, 2, 3, 4, 5, 6] as const

export const DATE_FORMAT = {
  DAY_OF_WEEK_FULL_NAME: 'ddd',
  MM_DD_YYYY: 'MM-DD-YYYY',
  MONTH_FULL_NAME: 'MMMM',
  SHORT_DATE: 'DD',
  SHORT_DATE_FORMAT: 'YYYY-MM-DD',
  SHORT_DAY: 'ddd',
  SHORT_MM: 'MM',
  SHORT_MONTH: 'MMM',
  SHORT_YEAR: 'YYYY',
  YEAR: 'YYYY',
  MONTH: 'MMMM',
  TWELVE_HRS: 'h:mm A',
  TWENTY_FOUR_HRS: 'HH:mm:ss'
}

export const SCREEN_OFFSET = 90

export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
export const PST_TZ = 'America/Los_Angeles'
export const FILTER_MONTH_FORMAT = 'mmm'

//Gallery
export const THUMBNAILS_PER_PAGE = 12

export const SWIPE_DISTANCE_LIMIT = 50

export const API_PATHS = {
  GET_SUBMISSIONS: 'artWorks/get-submissions/',
  CAST_VOTE: 'vote/castVote/'
} as const

export const EDUCATION_LEVELS = {
  Elementary_School: 'elementary-school',
  Middle_School: 'middle-school',
  High_School: 'high-school',
  College: 'college'
}

export const XO_PATHNAME_TO_DISPLAYNAME = {
  'xo-student-design-challenge': 'XO Student Design Challenge',
  'elementary-school': 'Elementary',
  'middle-school': 'Middle School',
  'high-school': 'High School',
  college: 'College'
} as const
