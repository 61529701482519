import { Button, Heading, Paragraph } from '~elements'
import styles from 'components/elements/PortalModal/PortalModal.module.scss'
import { ButtonProps } from '~types'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { useRouter } from 'next/router'
import { useEffect, useId } from 'react'

interface EventModalContentProps {
  modalBackground?: { url: string }
  description?: { html: string }
  disclaimer?: string
  statusBar?: string
  statusBarColor?: String
  title?: string
  buttonGroup?: Array<ButtonProps>
  businessUnit?: string
  setAriaAttributes?: (attributes: { labelledby?: string; describedby?: string }) => void
}

const MAX_DESCRIPTION_LENGTH = 160
const MAX_BUTTONS = 2

const EventModalContent = ({
  buttonGroup,
  description,
  disclaimer,
  statusBar,
  statusBarColor,
  title,
  businessUnit,
  setAriaAttributes
}: EventModalContentProps) => {
  const { query } = useRouter()
  const [source] = query.slug ? [...(query.slug as string[])].reverse() : ['/']

  const uniqueId = useId()
  const titleId = title ? `modal-title-${uniqueId}` : undefined
  const descriptionId = description?.html ? `modal-description-${uniqueId}` : undefined

  useEffect(() => {
    if (setAriaAttributes) {
      setAriaAttributes({
        labelledby: titleId,
        describedby: descriptionId
      })
    }
  }, [setAriaAttributes, titleId, descriptionId])

  const handleButtonClick = (buttonProps: ButtonProps) => {
    if (buttonProps.title.toLocaleLowerCase() === 'buy tickets') {
      const snakeCaseTitle = title
        ? title
            .split(' ')
            .map(x => x.toLocaleLowerCase())
            .join('_')
        : ''

      gaBuyTicketsClick({
        businessUnit,
        buyType: buttonProps.buyType,
        clickSource: source ?? '',
        clickTracking: `${window.location.href} ${snakeCaseTitle}:buy_tickets`,
        eventDate: '',
        eventVenueName: 'sphere_vegas',
        eventCategory: 'other',
        eventType: 'other events',
        eventDataStatus: '',
        linkLocation: EventModalContent.displayName,
        linkPosition: '',
        ticketmasterEventId: '',
        cmsIdentifier: buttonProps.cmsIdentifier,
        buttonPlacementDescription: buttonProps.buttonPlacementDescription
      })
    }
  }

  function stripHtmlTags(html: string) {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    return tempDiv.textContent || tempDiv.innerText || ''
  }

  const plainText = description?.html ? stripHtmlTags(description.html) : ''

  const truncatedText = plainText.length > MAX_DESCRIPTION_LENGTH ? plainText.substring(0, MAX_DESCRIPTION_LENGTH) : plainText

  const limitedButtonGroup = buttonGroup?.slice(0, MAX_BUTTONS)

  return (
    <div className={styles['modal-content--event']}>
      {statusBar && (
        <div className={styles['statusbar-container']}>
          <div className={`${styles.statusbar} ${styles[`statusbar-color-${statusBarColor}`]}`}>
            <p>{statusBar}</p>
          </div>
        </div>
      )}
      {title && (
        <Heading level="2" justify="center" id={titleId}>
          {title}
        </Heading>
      )}
      {description?.html && (
        <div className={styles['description-container']} id={descriptionId}>
          <Paragraph text={{ html: truncatedText }} />
        </div>
      )}

      {limitedButtonGroup && limitedButtonGroup.length > 0 && (
        <div className={styles['btn-container']}>
          {limitedButtonGroup.map((buttonProps, index) => {
            if (buttonProps.isEnabled) {
              return <Button key={`${buttonProps.title}_${index}`} {...buttonProps} onClick={() => handleButtonClick(buttonProps)} />
            }
          })}
        </div>
      )}
      {disclaimer && (
        <div className={styles['disclaimer-container']}>
          <p>{disclaimer}</p>
        </div>
      )}
    </div>
  )
}

export default EventModalContent

EventModalContent.displayName = 'event_modal_content'
