import React from 'react'
import { ArtistIntroCardType } from '~types'
import styles from './ArtistIntroCard.module.scss'
import { constructS3Url } from 'utils'

const ArtistIntroCard = ({ backgroundImage, logo, title, logoUrlS3, backgroundImageUrlS3 }: ArtistIntroCardType) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url("${constructS3Url(backgroundImageUrlS3)}")`
      }}
    >
      {title && <div className={styles.title}>{title}</div>}
      {logo && (
        <div className={styles['logo-container']}>
          <img className={styles.logo} alt="logo" src={constructS3Url(logoUrlS3)} />
        </div>
      )}
    </div>
  )
}

export default ArtistIntroCard
