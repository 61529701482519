import Link from 'next/link'
import { useRouter } from 'next/router'
import { Heading, SocialMediaRow } from '~elements'
import { FooterPropsStandalone, NavigationMenuItemProps, NavigationMenuItemsProps } from '~types'
import { ANALYTICS_LINK_LOCATION_FOOTER, CTA_CLICK_EVENT } from '~analyticsConstants'
import { analyticsClickHandler } from '~analyticsUtils'
import styles from './FooterStandalone.module.scss'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { useWindowSize } from 'utils/hooks'
import { Fragment } from 'react'

const FooterStandalone = ({ navMenuLegal, socialMediaIcons }: FooterPropsStandalone) => {
  const { asPath, query } = useRouter()

  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')

  const analyticsClick = (title: string) => {
    analyticsClickHandler(title, asPath)
    gaBtnClick(CTA_CLICK_EVENT, ANALYTICS_LINK_LOCATION_FOOTER, title)
  }

  const findReplaceDynamicYear = (text: string) => {
    return text.indexOf('${YEAR}') !== -1 ? text.replace('${YEAR}', new Date().getFullYear()) : text
  }

  const footerNavMenu = (menuItems: NavigationMenuItemsProps, headline: string) => {
    return (
      <>
        {menuItems.length ? (
          <>
            {headline && (
              <Heading level={6} levelDisplay="eyebrow" color="light">
                {headline}
              </Heading>
            )}
            <ul className={styles['column__content']}>
              {menuItems &&
                menuItems.map(
                  ({ url, isTargetBlank, title }: NavigationMenuItemProps, navIndex: number) =>
                    url && (
                      <Fragment key={navIndex}>
                        {
                          <li key={navIndex} onClick={() => analyticsClick(title)}>
                            <Link href={url} target={isTargetBlank ? '_blank' : '_self'}>
                              {findReplaceDynamicYear(title)}
                            </Link>
                          </li>
                        }
                      </Fragment>
                    )
                )}
            </ul>
          </>
        ) : null}
      </>
    )
  }
  return (
    <>
      <footer className={styles['footer-global']}>
        <div className={styles['row']}>
          <div className={styles['column-1']}>
            <SocialMediaRow align={isBelowBreakpointMd ? 'center' : 'left'} socialMediaRow={socialMediaIcons} />
          </div>
          <div className={styles['column-2']}>{footerNavMenu(navMenuLegal, '')}</div>
        </div>
      </footer>
    </>
  )
}

export default FooterStandalone
