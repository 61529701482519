import { Heading } from '~elements'
import { InfoBarSection } from '~modules'
import { Col, Row } from 'react-grid-system'
import { ButtonProps } from '~types'
import styles from './InfoBar.module.scss'
import { useWindowSize } from 'utils/hooks'
import cx from 'classnames'
import { Fragment } from 'react'
import { constructS3Url } from 'utils'

interface InfoBarSectionProps {
  cta: ButtonProps
  description: string
  subheadline: string
  icon: { url: string }
  iconHeight: number
  iconWidth: number
  index: number
  highlightedWords: string
  gradientColor: string
  displayGradient: boolean
  gradientPlacementOnTop: boolean
}

interface InfoBarProps {
  backgroundImage: { url: string }
  headline: string
  highlightedWords: string
  gradientColor: string
  displayGradient: boolean
  gradientPlacementOnTop: boolean
  infoBarSections: Array<InfoBarSectionProps>
  backgroundImageUrlS3: string
}

const InfoBar = ({
  headline,
  infoBarSections,
  backgroundImage,
  highlightedWords,
  gradientColor,
  displayGradient,
  gradientPlacementOnTop,
  backgroundImageUrlS3
}: InfoBarProps) => {
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  return (
    <>
      {displayGradient && gradientPlacementOnTop && (
        <div className={cx(styles[`linear-gradient-top-${gradientColor}`], styles['gradient-height'])}></div>
      )}
      <div className={styles.container} style={{ backgroundImage: `url("${constructS3Url(backgroundImageUrlS3)}")` }}>
        <div className={styles['infobar']}>
          {headline && (
            <Heading level={1}>
              {highlightedWords && <span className={styles['highlight-word']}>{highlightedWords + ' '}</span>}
              {headline}
            </Heading>
          )}
          <Row>
            {infoBarSections &&
              infoBarSections.map((infoBarSections, index) =>
                index % 2 === 0 ? (
                  <Fragment key={index}>
                    <Col className={styles['container-padding']} lg={5.5}>
                      <InfoBarSection {...infoBarSections} />
                    </Col>{' '}
                    <Col lg={1} key={index} className={styles['margin-auto']}>
                      <div className={isBelowBreakpointMd ? styles['horizontal-line'] : styles['vertical-line']} />
                    </Col>
                  </Fragment>
                ) : (
                  <Col className={styles['container-padding']} lg={5.5} key={index}>
                    <InfoBarSection {...infoBarSections} />
                  </Col>
                )
              )}
          </Row>
        </div>
      </div>
      {displayGradient && !gradientPlacementOnTop && (
        <div className={cx(styles[`linear-gradient-bottom-${gradientColor}`], styles['gradient-height'])}></div>
      )}
    </>
  )
}

export default InfoBar
