import { useRef, useState, lazy, Suspense, useEffect } from 'react'
import styles from './TileCarousel.module.scss'
import { TWENTY_FOUR_HOURS_IN_MS } from '~constants'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Heading, PlayButton, PauseButton, PortalModal } from '~elements'
import { useWindowSize, useIsSmartphoneLandscape } from 'utils/hooks'
import DetailsCard from '../DetailsCard/DetailsCard'
import { gaDLPush } from 'utils/hooks/gaDLPush'
// @ts-ignore
import Slider from 'react-slick'
import Image from 'next/image'
import { isMobileOnly } from 'react-device-detect'
import EventModalContent from '../EventModalContent/EventModalContent'
import { preloadImageByUrl } from 'utils'

const BackgroundVideo = lazy(() => import('components/elements/CarouselVideo/CarouselVideo'))

interface IUrl {
  url: string
}

interface TileCarouselProps {
  items: {
    image: {
      url: string
    }
    imageUrlS3: string
    text: string
    cmsIdentifier: string
  }[]
  onTileClick?: (index: number) => void
  loopCarousel: boolean
  detailCards: any
  carouselBackground: {
    gif: IUrl[]
    image: IUrl[]
    videos: {
      posterImage: IUrl[]
      video: IUrl[]
      posterImageUrlS3: string
    }[]
    imageUrlS3: string
  }[]
  businessUnit?: string
  modalBackground?: {
    url: string
  }
  eventModalContent: any
}

const TileCarousel = ({ items, loopCarousel = true, detailCards, carouselBackground, businessUnit, eventModalContent }: TileCarouselProps) => {
  const sliderRef = useRef(null)
  const [detailsCardHeight, setDetailsCardHeight] = useState<number>(0)
  const [activeTileIndex, setActiveTileIndex] = useState<number>(0)
  const [isVideoPaused, setIsVideoPaused] = useState<boolean>(false)
  const [lastUpdateTime, setLastUpdateTime] = useState<number>(Date.now())
  const [disableTransition, setDisableTransition] = useState<boolean>(false)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const isSmartphoneLandscape = useIsSmartphoneLandscape() || isMobileOnly
  const autoplaySpeed = 7000
  const [playButtonActive, setPlayButtonActive] = useState(false)
  const [activeBackgroundImage, setActiveBackgroundImage] = useState<string>()
  const [activeBackgroundGif, setActiveBackgroundGif] = useState<string>()
  const [mediaDetails, setMediaDetails] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [ariaAttributes, setAriaAttributes] = useState<{ labelledby?: string; describedby?: string }>({})
  const [isImgLoaded, setIsImgLoaded] = useState(false)
  const [preloadImage, setPreloadImage] = useState(null)

  useEffect(() => {
    if (isModalOpen && eventModalContent?.modalBackground.url) {
      preloadImageByUrl(eventModalContent.modalBackground.url)
        .then(src => {
          setPreloadImage(src)
          setIsImgLoaded(true)
        })
        .catch(error => {
          setIsImgLoaded(false)
        })
    }
  }, [isModalOpen, eventModalContent])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const modalClosedAt = localStorage.getItem('modalClosedAt')
      const now = new Date().getTime()

      if (modalClosedAt) {
        const elapsed = now - parseInt(modalClosedAt, 10)

        if (elapsed < TWENTY_FOUR_HOURS_IN_MS) {
          setIsModalOpen(false)
          localStorage.setItem('isModalOpen', 'false')
          return
        } else {
          localStorage.removeItem('modalClosedAt')
        }
      }

      const storedIsModalOpen = localStorage.getItem('isModalOpen')
      if (storedIsModalOpen === 'true') {
        setIsModalOpen(true)
      } else {
        const timer = setTimeout(() => {
          setIsModalOpen(true)
        }, 5000)

        return () => clearTimeout(timer)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('isModalOpen', isModalOpen.toString())
    }
  }, [isModalOpen])

  const handleModalClose = () => {
    if (typeof window !== 'undefined') {
      const now = new Date().getTime()
      localStorage.setItem('modalClosedAt', now.toString())
      localStorage.setItem('isModalOpen', 'false')
    }
    setIsModalOpen(false)
  }

  const next = () => {
    if (sliderRef.current) {
      // @ts-ignore
      sliderRef.current.slickNext()
    }
  }

  const previous = () => {
    if (sliderRef.current) {
      // @ts-ignore
      sliderRef.current.slickPrev()
    }
  }

  const slidePlay = () => {
    if (sliderRef.current) {
      // @ts-ignore
      sliderRef.current.slickPlay()
    }
  }

  const slidePause = () => {
    if (sliderRef.current) {
      // @ts-ignore
      sliderRef.current.slickPause()
    }
  }

  const handleManualClick = (ctaTitle: string, linkPosition: number, cmsIdentifier: string) => {
    gaDLPush({
      event: 'thumbnail_click',
      click_source: '/',
      click_tracking: `hero:thumbnail-cta:${ctaTitle.replaceAll(' ', '-')}`,
      click_type: 'cta',
      link_location: 'body',
      link_module: `hp_hero`,
      link_position: linkPosition,
      link_type: 'internal',
      cms_identifier: cmsIdentifier
    })
  }

  const carouselSettings = {
    customPaging: function (i: number) {
      const item = items[i]

      const handleMouseEnter = (i: number) => {
        if (!playButtonActive) {
          setIsVideoPaused(true)
        }
      }
      const handleMouseLeave = (i: number) => {
        if (!playButtonActive) {
          setIsVideoPaused(false)
        }
      }

      return (
        <div
          key={i}
          className={cx(styles['tile'], { [styles['tile-active']]: activeTileIndex === i })}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={() => handleMouseLeave(i)}
        >
          <div onClick={() => handleManualClick(item.text, i, item.cmsIdentifier)}>
            <Image src={item.imageUrlS3} alt={item.text} className={styles['tile-image']} width={300} height={175} />
            <div className={styles['tile-text']}>
              <Heading level={4}>{item.text}</Heading>
            </div>
          </div>
        </div>
      )
    },
    arrows: false,
    dots: true,
    dotsClass: styles.tiles,
    autoplay: true,
    infinite: loopCarousel,
    speed: 500,
    autoplaySpeed: autoplaySpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      setDisableTransition(true)
      setActiveTileIndex(next)
      setTimeout(() => setDisableTransition(false), 5)
    },
    appendDots: (dots: React.ReactNode) => (
      <div>
        <ul data-type-landscape={isSmartphoneLandscape} className={cx('slick-dots', { [styles.tiles]: !isBelowBreakpointMd })}>
          {' '}
          {dots}{' '}
        </ul>
      </div>
    )
  }

  const getCarouselSettings = () => {
    if (isBelowBreakpointMd || isSmartphoneLandscape) {
      const { customPaging, ...rest } = carouselSettings
      return rest
    }

    return carouselSettings
  }

  useEffect(() => {
    if (isVideoPaused) {
      slidePause()
    } else {
      setLastUpdateTime(Date.now())
      slidePlay()
    }
  }, [isVideoPaused])

  const togglePlay = () => {
    setIsVideoPaused(false)
    setPlayButtonActive(false)
  }

  const togglePause = () => {
    setIsVideoPaused(true)
    setPlayButtonActive(true)
  }

  useEffect(() => {
    if (carouselBackground) {
      const details = carouselBackground.map(({ gif, videos, imageUrlS3 }) => ({
        activeBackgroundVideo: videos.length > 0 && videos[0].video && videos[0].video.length > 0 && videos[0].video[0],
        activeBackgroundPosterImage: videos.length > 0 && videos[0].posterImageUrlS3,
        activeBackgroundImage: imageUrlS3,
        activeBackgroundGif: gif.length > 0 && gif[0].url
      }))
      setMediaDetails(details)
      if (details[activeTileIndex]) {
        setActiveBackgroundImage(details[activeTileIndex].activeBackgroundImage)
        setActiveBackgroundGif(details[activeTileIndex].activeBackgroundGif)
      }
    }
  }, [activeTileIndex, carouselBackground])

  return (
    <div
      className={styles['home-page-container']}
      style={{
        backgroundImage: activeBackgroundImage ? `url(${activeBackgroundImage})` : activeBackgroundGif ? `url(${activeBackgroundGif})` : 'none'
      }}
    >
      <Slider {...getCarouselSettings()} ref={sliderRef}>
        {mediaDetails?.map(({ activeBackgroundPosterImage, activeBackgroundVideo }, index) => {
          return (
            <div key={index}>
              <div
                style={{
                  opacity: '60%'
                }}
              >
                {activeBackgroundVideo && activeBackgroundPosterImage && (
                  <Suspense fallback={<>Loading...</>}>
                    <BackgroundVideo
                      autoPlay={!isVideoPaused}
                      videoLoop={true}
                      posterImage={typeof activeBackgroundPosterImage === 'object' ? activeBackgroundPosterImage : { url: '' }}
                      backgroundVideo={activeBackgroundVideo}
                      isPaused={activeTileIndex !== index ? null : isVideoPaused}
                      detailsCardHeight={detailsCardHeight}
                    />
                  </Suspense>
                )}
              </div>
            </div>
          )
        })}
      </Slider>
      <DetailsCard
        description={detailCards[activeTileIndex].description}
        headline={detailCards[activeTileIndex].headline}
        eyebrow={detailCards[activeTileIndex].eyebrow}
        cta={detailCards[activeTileIndex].cta}
        isDescriptionVisibleDesktopOrTablet={detailCards[activeTileIndex].isDescriptionVisibleDesktopOrTablet}
        isDescriptionVisibleMobile={detailCards[activeTileIndex].isDescriptionVisibleMobile}
        eyebrowColor={detailCards[activeTileIndex].eyebrowColor}
        setDetailsCardHeight={setDetailsCardHeight}
        tileIndex={activeTileIndex}
        businessUnit={businessUnit}
      />
      {eventModalContent && isImgLoaded && preloadImage && (
        <PortalModal
          isOpen={isModalOpen}
          handleModalClose={handleModalClose}
          modalBackgroundUrl={preloadImage}
          type="event"
          ariaLabelledBy={ariaAttributes.labelledby}
          ariaDescribedBy={ariaAttributes.describedby}
        >
          <EventModalContent
            description={eventModalContent.description}
            disclaimer={eventModalContent.disclaimer}
            statusBar={eventModalContent.statusBar}
            title={eventModalContent.title}
            statusBarColor={eventModalContent.statusBarColor}
            buttonGroup={eventModalContent.buttonGroup}
            businessUnit={businessUnit}
            setAriaAttributes={setAriaAttributes}
          />
        </PortalModal>
      )}
      <div className={styles['slider-nav-play-pause-container']}>
        <div>
          {activeBackgroundGif || activeBackgroundImage || isBelowBreakpointMd || isSmartphoneLandscape ? null : (
            <div className={cx([styles['play-pause-container']])}>
              {playButtonActive ? (
                <div className={cx([styles['play-pause']])} onClick={togglePlay}>
                  <PlayButton />
                </div>
              ) : (
                <div className={cx([styles['play-pause']])} onClick={togglePause}>
                  <PauseButton />
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          {!isBelowBreakpointMd && !isSmartphoneLandscape && (
            <div className={styles['slider-nav-container']}>
              <div className={cx([styles['slick-arrow-custom']])} onClick={previous}>
                <FontAwesomeIcon icon={faChevronLeft} size="lg" />
              </div>
              <div className={cx([styles['slick-arrow-custom']])} onClick={next}>
                <FontAwesomeIcon icon={faChevronRight} size="lg" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TileCarousel
