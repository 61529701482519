import React from 'react'
import styles from './CardList.module.scss'
import parse from 'html-react-parser'
import { Button, Heading } from '~elements'
import Image from 'next/image'
import classNames from 'classnames'
import { constructS3Url } from 'utils'

interface CardListProps {
  description: { html: string }
  details: { html: string }
  headline: string
  image: { url: string; fileName: string }
  label: string
  subHeadline: string
  groupCards: any
  imageUrlS3: string
}

const Card = (description, details, headline, imageUrlS3, subHeadline, buttonProps) => {
  const conditionalBackgroundCard = classNames(styles.card, {
    [styles['transparent-background']]: buttonProps !== undefined
  })
  return (
    <div className={conditionalBackgroundCard}>
      {imageUrlS3 && (
        <div className={'image-container'}>
          <Image src={constructS3Url(imageUrlS3)} alt={headline} width="0" height="0" sizes="100vw" priority />
        </div>
      )}
      {headline ? <Heading level={3}>{headline}</Heading> : null}
      {subHeadline ? (
        <Heading level={4} levelDisplay="eyebrow" color="accent">
          {subHeadline}
        </Heading>
      ) : null}
      {description && description.html ? <div className={styles['card-description']}>{parse(description.html)}</div> : null}
      {details ? <div className={styles['card-details']}>{parse(details.html)}</div> : null}

      {buttonProps?.length &&
        buttonProps?.map((button, index) => (
          <Button
            key={`card-btn-${index}`}
            color={button?.color}
            fill={button?.fill}
            isFullWidth={button?.isFullWidth}
            size={button?.size}
            title={button?.title}
            url={button?.url}
            bgColor={button?.bgColor}
            justify={button?.justify}
          />
        ))}
    </div>
  )
}

const CardList = ({ description, details, headline, subHeadline, groupCards, imageUrlS3 }: CardListProps) => {
  if (groupCards && groupCards.length > 0) {
    return (
      <div className={styles['cards-plan-visit-page']}>
        {groupCards.map(({ description, details, headline, imageUrlS3, subHeadline, cta }, index) => {
          return <div key={index}>{Card(description, details, headline, imageUrlS3, subHeadline, cta)}</div>
        })}
      </div>
    )
  } else {
    return <>{Card(description, details, headline, imageUrlS3, subHeadline)}</>
  }
}

export default CardList
