import { ConstantMapProps } from '~types'
import parse, { HTMLReactParserOptions, Element, attributesToProps } from 'html-react-parser'

const disableBodyScroll = (modalOpen: boolean) => {
  const html = document.querySelector('html')
  const body = document.querySelector('body')

  if (modalOpen) {
    html.style.overflowY = 'hidden'
    body.style.overflowY = 'hidden'
    // required for iOS
    html.style.touchAction = 'none'
    body.style.touchAction = 'none'
  } else {
    html.style.overflowY = 'auto'
    body.style.overflowY = 'auto'
    html.style.touchAction = 'auto'
    body.style.touchAction = 'auto'
  }
}

const convertDateToMDYYYY = (date: string) => {
  if (!date) return
  const [year, month, day] = date.split('-')
  if (!year || !month || !day) return
  const noLeadingZeroMonth = month.replace(/^0+/, '')
  const noLeadingZeroDay = day.replace(/^0+/, '')
  return `${noLeadingZeroMonth}-${noLeadingZeroDay}-${year}`
}

const objHasValue = (obj: object, key: string, value: string) => {
  return obj.hasOwnProperty(key) && obj[key] === value
}

const getConstantsMapFromCms = (constantMaps: Array<ConstantMapProps>) => {
  return constantMaps.reduce<Record<string, object>>((result, map) => {
    result[map.title] = map.value
    return result
  }, {})
}

const isTouchDevice = () => {
  if (typeof window !== 'undefined') return navigator.maxTouchPoints > 0
}

const isPastDeadline = (deadline: string) => {
  const now = new Date()
  const parsedDeadline = new Date(deadline)

  return now.getTime() >= parsedDeadline.getTime()
}

const parseHTMLString = (text: { html: string }): string | JSX.Element | JSX.Element[] => {
  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      const { type, name, attribs, children } = domNode
      if (domNode instanceof Element && type === 'tag' && name === 'a') {
        const props = attributesToProps(attribs)
        if (props && props.href && props.href.startsWith('/')) {
          const [anchor] = children
          const anchorLabel = anchor?.data
          const requestCameFromUrl = props.href
          const updatedProps = {
            ...props,
            href: requestCameFromUrl
          }
          return <a {...updatedProps}>{anchorLabel}</a>
        }
      }
    }
  }

  return parse(text.html, options)
}

const getBackgroundGradientStyle = (gradient: string, color = '#014154', isBelowBreakpoint: boolean) => {
  switch (gradient) {
    case 'radial_gradient':
      return isBelowBreakpoint
        ? `radial-gradient(74.4% 79.93% at 100% 100%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(174deg, #000 13.09%, ${color} 96.05%)`
        : `radial-gradient(54.35% 68.23% at 100% 100%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(100deg, #000 -2.38%, ${color} 71.5%)`
    case 'linear_gradient':
      return `linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, ${color} 100%)`
    case 'transparent':
      return 'transparent'
  }
}

const createFormstackPayload = (fields: any, userInfo: any) => {
  const payload = {} as any
  fields.forEach((field: any) => {
    if (field.name.length) {
      const name = field.name.toLowerCase().replaceAll('_', '')
      const id = field.id
      payload[`field_${id}`] = userInfo[name].value
    }
  })
  return payload
}

const createPostCall = (payload: object, url: string, responseType: string = 'json', headers = { 'Content-Type': 'application/json' }) =>
  fetch(url, {
    body: JSON.stringify(payload),
    headers: new Headers(headers),
    method: 'POST'
  }).then(response => {
    if (responseType === 'text') {
      return response.text()
    } else {
      return response.json()
    }
  })

const isValidURL = (url: string) => {
  try {
    new URL(url)
    return true
  } catch (error) {
    return false
  }
}

const constructURL = (url: string, { key, value }: { key: string; value: string }) => {
  if (!url) {
    return ''
  }

  if (isValidURL(url)) {
    const uri = new URL(url)

    if (!uri.searchParams.has(key)) uri.searchParams.append(key, value)

    return decodeURIComponent(uri.toString())
  } else {
    if (url.indexOf('#') >= 0) {
      const [path, hash] = url.split('#')
      return `${path}?camefrom=${value}#${hash}`
    } else if (url.indexOf('?') >= 0) {
      return `${url}&camefrom=${value}`
    } else {
      return `${url}?camefrom=${value}`
    }
  }
}

const constructS3Url = (assetPath: string) => {
  if (assetPath) {
    const s3UrlPrefix = process.env.NEXT_PUBLIC_S3_URL_PREFIX
    return `${s3UrlPrefix}${assetPath}`
  }
  return ''
}

const preloadImageByUrl = (src: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.src = src
    image.onload = () => resolve(src)
    image.onerror = reject
  })

export {
  disableBodyScroll,
  convertDateToMDYYYY,
  getConstantsMapFromCms,
  getBackgroundGradientStyle,
  objHasValue,
  parseHTMLString,
  preloadImageByUrl,
  isTouchDevice,
  isPastDeadline,
  createFormstackPayload,
  createPostCall,
  constructURL,
  constructS3Url
}
