import { ButtonProps } from '~types'
import { HeroEDPHeaderV2Props, AssetHeight, descriptionContainerWidth } from './../types'
import styles from './HeroEdpHeaderDesktop.module.scss'
import Image from 'next/image'
import { Button, Heading, Paragraph } from '~elements'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { useRouter } from 'next/router'

const HeroEdpHeaderDesktop = ({
  backgroundAsset,
  eyebrow,
  eyebrowColor,
  headline,
  description,
  assetHeight,
  cta,
  showVideoOnDesktop,
  isButtonVisible,
  isDescriptionVisible,
  isEyebrowVisbile,
  isHeadlineVisible,
  descriptionContainerWidth
}: HeroEDPHeaderV2Props) => {
  const heightClassMap: { [key in AssetHeight]: string } = {
    vh33: styles['hero-container__background-height--third'],
    vh50: styles['hero-container__background-height--half'],
    vh100: styles['hero-container__background-height--full']
  }

  const widthClassMap: { [key in descriptionContainerWidth]: string } = {
    width1600px: styles['hero-details-container__width--1600px'],
    width1080px: styles['hero-details-container__width--1080px']
  }

  const { query } = useRouter()
  const [source] = [...(query.slug as string[])].reverse()

  const handleButtonClick = (buttonProps: ButtonProps, isScrollIntoView: boolean) => {
    if (isScrollIntoView) {
      const targetElement = buttonProps.anchorLink ? document.getElementById(buttonProps.anchorLink) : null
      if (buttonProps && buttonProps.anchorLink && targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    }

    gaBuyTicketsClick({
      buyType: buttonProps.buyType,
      clickSource: source ?? '',
      clickTracking: `${window.location.href} hero_edp_header:buy_tickets`,
      eventDate: '',
      eventVenueName: 'sphere_vegas',
      eventCategory: 'other',
      eventType: 'other events',
      eventDataStatus: '',
      linkLocation: HeroEdpHeaderDesktop.displayName,
      linkPosition: '',
      ticketmasterEventId: '',
      cmsIdentifier: buttonProps.cmsIdentifier,
      buttonPlacementDescription: buttonProps.buttonPlacementDescription
    })
  }

  let content = null

  if (backgroundAsset?.video && showVideoOnDesktop) {
    const posterImageUrl = backgroundAsset.video.posterImage?.[0]?.url
    const videoUrl = backgroundAsset.video.video?.[0]?.url
    content = (
      <video autoPlay loop muted playsInline poster={posterImageUrl} disableRemotePlayback>
        <source src={videoUrl} type="video/mp4" />
      </video>
    )
  } else if (backgroundAsset?.image && !showVideoOnDesktop) {
    const imageUrl = backgroundAsset.image.url
    content = <Image src={imageUrl} alt={headline || 'Background Image'} fill style={{ objectFit: 'cover' }} />
  }

  return (
    <div className={styles['hero-container__desktop']}>
      <div className={`${styles['hero-container__background']} ${heightClassMap[assetHeight]}`}>
        <div className={styles['background-content']}>{content}</div>
        <div className={`${styles['details-container']} ${widthClassMap[descriptionContainerWidth]}`}>
          <div className={styles['headings']}>
            {eyebrow && isEyebrowVisbile && (
              <Heading level={5} levelDisplay={5} color={eyebrowColor}>
                {eyebrow}
              </Heading>
            )}
            {headline && isHeadlineVisible && <Heading level={1}>{headline}</Heading>}
            {description?.html && isDescriptionVisible && (
              <div className={styles['description-container']}>
                <Paragraph text={description} />
              </div>
            )}
          </div>
          {cta && isButtonVisible && cta.length > 0 && (
            <div className={styles['btn-container']}>
              {cta.map((buttonProps: ButtonProps, index: number) => (
                <Button
                  key={`${buttonProps.title}_${index}`}
                  {...buttonProps}
                  {...(buttonProps.anchorLink
                    ? { onClick: () => handleButtonClick(buttonProps, true) }
                    : { ...buttonProps, onClick: () => handleButtonClick(buttonProps, false) })}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

HeroEdpHeaderDesktop.displayName = 'hero_edp_header'
export default HeroEdpHeaderDesktop
